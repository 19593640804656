.headerRow {
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.expandColumn {
    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 18px;
        div {
            display: inline-block;
            //transform: rotate(90deg);
        }
    }
}

.bodyRow {
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}